// Boosted mod
.link-chevron {
  font-weight: $font-weight-bold;
  text-decoration: if($link-decoration == none, null, none);

  &::after {
    display: inline-block;
    width: $linked-chevron-icon-width;
    height: $linked-chevron-icon-height;
    margin-left: $linked-chevron-margin-left;
    vertical-align: middle;
    content: "";
    background-image: var(--#{$prefix}chevron-icon);
    background-repeat: no-repeat;
    transform: $linked-chevron-transform;
  }

  &:hover {
    text-decoration: $link-decoration;

    &::after {
      filter: $orange-filter;
    }
  }
}
// End mod
