//
// Orange stepped process
//

.stepped-process {
  // scss-docs-start stepped-process-css-vars
  --#{$prefix}stepped-process-font-size: #{$stepped-process-font-size};
  --#{$prefix}stepped-process-font-weight: #{$stepped-process-font-weight};
  --#{$prefix}stepped-process-counter: #{$stepped-process-counter};
  --#{$prefix}stepped-process-item-padding-y: #{$step-item-padding};
  --#{$prefix}stepped-process-item-padding-x: 0;
  --#{$prefix}stepped-process-item-margin-end: #{$step-item-margin-end};
  --#{$prefix}stepped-process-item-bg: #{$step-item-bg};
  --#{$prefix}stepped-process-item-active-bg: #{$step-item-active-bg};
  --#{$prefix}stepped-process-item-next-bg: #{$step-item-next-bg};
  --#{$prefix}stepped-process-item-drop-shadow: none;
  --#{$prefix}stepped-process-item-arrow-width: 0;
  --#{$prefix}stepped-process-item-arrow-shape: #{$step-item-arrow-shape};
  --#{$prefix}stepped-process-link-width: #{$step-link-width};
  --#{$prefix}stepped-process-link-line-height: #{$step-link-line-height};
  --#{$prefix}stepped-process-link-color: #{$step-link-color};
  --#{$prefix}stepped-process-link-active-color: #{$step-link-active-color};
  --#{$prefix}stepped-process-link-next-color: #{$step-link-next-color};
  --#{$prefix}stepped-process-link-marker: #{$step-link-marker};
  --#{$prefix}stepped-process-link-text-decoration: #{$step-link-text-decoration};

  @include media-breakpoint-up(sm) {
    --#{$prefix}stepped-process-item-padding-x: 0;
    --#{$prefix}stepped-process-item-margin-end: 0;
    --#{$prefix}stepped-process-item-drop-shadow: #{$step-item-drop-shadow};
    --#{$prefix}stepped-process-item-arrow-width: #{$step-item-arrow-width};
  }

  @include media-breakpoint-up(xl) {
    --#{$prefix}stepped-process-link-width: none;
    --#{$prefix}stepped-process-link-marker: #{$step-link-marker-lg};
  }
  // scss-docs-end stepped-process-css-vars

  ol {
    display: flex;
    padding: 0;
    margin: 0;
    overflow: hidden;
    font-size: var(--#{$prefix}stepped-process-font-size);
    font-weight: var(--#{$prefix}stepped-process-font-weight);
    text-align: center;
    list-style: none;
    counter-reset: var(--#{$prefix}stepped-process-counter);
  }
}

.stepped-process-item {
  position: relative;
  display: flex;
  flex: 1;
  padding: var(--#{$prefix}stepped-process-item-padding-y) var(--#{$prefix}stepped-process-item-padding-x);
  counter-increment: var(--#{$prefix}stepped-process-counter);
  background-color: var(--#{$prefix}stepped-process-item-bg);
  filter: var(--#{$prefix}stepped-process-item-drop-shadow);

  &:not(:last-child) {
    margin-right: var(--#{$prefix}stepped-process-item-margin-end);

    &::after {
      position: absolute;
      top: 0;
      bottom: 0;
      left: subtract(100%, 1px);
      z-index: -1;
      width: var(--#{$prefix}stepped-process-item-arrow-width);
      clip-path: var(--#{$prefix}stepped-process-item-arrow-shape);
      content: "";
      background-color: inherit;
    }
  }

  &.active {
    background-color: var(--#{$prefix}stepped-process-item-active-bg);
  }

  .active ~ & {
    background-color: var(--#{$prefix}stepped-process-item-next-bg);
  }

  // stylelint-disable-next-line scss/selector-no-redundant-nesting-selector
  & + .stepped-process-item {
    padding-left: var(--#{$prefix}stepped-process-item-arrow-width);
  }
}

.stepped-process-link {
  flex: 1 0 var(--#{$prefix}stepped-process-link-width);
  max-width: var(--#{$prefix}stepped-process-link-width);
  margin: auto;
  overflow: hidden;
  line-height: var(--#{$prefix}stepped-process-link-line-height);
  color: var(--#{$prefix}stepped-process-link-color);
  text-decoration: if($link-decoration == none, null, none);
  white-space: nowrap;
  outline-color: var(--#{$prefix}focus-visible-inner-color);
  box-shadow: none;

  &::before {
    content: var(--#{$prefix}stepped-process-link-marker);
  }

  &:hover {
    color: var(--#{$prefix}stepped-process-link-color);
  }

  &[href]:hover {
    text-decoration: if($link-hover-decoration == underline, null, underline);
  }

  .active & {
    color: var(--#{$prefix}stepped-process-link-active-color);
    outline-color: $step-link-active-outline;
  }

  .active ~ .stepped-process-item & {
    color: var(--#{$prefix}stepped-process-link-next-color);
    outline-color: var(--#{$prefix}focus-visible-outer-color);
  }
}

@for $i from 1 through $stepped-process-max-items {
  .stepped-process-item:nth-child(#{$i}) {
    $index: subtract($stepped-process-max-items, $i);
    z-index: if($index == 0, null, $index);
  }
}

@include media-breakpoint-up(sm) {
  .active .stepped-process-link {
    --#{$prefix}stepped-process-link-marker: #{$step-link-marker-lg};
    max-width: none;
  }
}

@include media-breakpoint-between(sm, xl) {
  .stepped-process-item.active {
    flex: $stepped-process-max-items - 1;
  }
}

.stepped-process-dark {
  // scss-docs-start stepped-process-dark-css-vars
  --#{$prefix}stepped-process-item-bg: #{$step-item-dark-bg};
  --#{$prefix}stepped-process-item-active-bg: #{$step-item-dark-active-bg};
  --#{$prefix}stepped-process-item-next-bg: #{$step-item-dark-next-bg};
  --#{$prefix}stepped-process-item-drop-shadow: #{$step-item-dark-drop-shadow};
  --#{$prefix}stepped-process-link-color: #{$step-link-dark-color};
  --#{$prefix}stepped-process-link-active-color: #{$step-link-dark-active-color};
  --#{$prefix}stepped-process-link-next-color: #{$step-link-dark-next-color};
  // scss-docs-end stepped-process-dark-css-vars
}
