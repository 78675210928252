//
// Headings
//
.h1 {
  @extend h1;
}

.h2 {
  @extend h2;
}

.h3 {
  @extend h3;
}

.h4 {
  @extend h4;
}

.h5 {
  @extend h5;
}

.h6 {
  @extend h6;
}


// Boosted mod
// Headings and displays together
// = Add letter-spacing and line-height
// = Reduce heading font-sizes for mobile (mobile first, obviously)
[class*="display-"] {
  @extend %heading;
}

.display-1 {
  @include font-size($h2-font-size);
  line-height: $h2-line-height;

  /* rtl:remove */
  letter-spacing: $h2-spacing;
}

h1,
.display-2,
.display-3 {
  @include font-size($h3-font-size);
  line-height: $h3-line-height;

  /* rtl:remove */
  letter-spacing: $mid-spacing;
}

/* rtl:begin:remove */
.display-2 {
  letter-spacing: $h3-spacing;
}

/* rtl:end:remove */

h2,
.display-4 {
  @include font-size($h5-font-size);
  line-height: $h5-line-height;

  /* rtl:remove */
  letter-spacing: $h5-spacing;
}

.lead {
  @include font-size($h6-font-size);
  font-weight: $lead-font-weight;
  line-height: $h6-line-height;

  /* rtl:remove */
  letter-spacing: $h6-spacing;
}

@include media-breakpoint-up(sm) {
  h1,
  .display-1,
  .display-2,
  .display-3 {
    line-height: $display-line-height;
  }

  .display-1 {
    @include font-size($display2-size);

    /* rtl:remove */
    letter-spacing: $display2-spacing;
  }

  .display-2 {
    @include font-size($display3-size);

    /* rtl:remove */
    letter-spacing: $display3-spacing;
  }

  h1,
  .display-3 {
    @include font-size($display4-size);

    /* rtl:remove */
    letter-spacing: $display4-spacing;
  }

  h2,
  h3,
  .display-4 {
    @include font-size($h3-font-size);
    line-height: $h3-line-height;

    /* rtl:remove */
    letter-spacing: $h3-spacing;
  }

  h4,
  h5,
  h6,
  .lead {
    @include font-size($h5-font-size);
    line-height: $h5-line-height;

    /* rtl:remove */
    letter-spacing: $h5-spacing;
  }
}

@include media-breakpoint-up(lg) {
  .display-1 {
    @include font-size($display1-size);

    /* rtl:remove */
    letter-spacing: $display1-spacing;
  }

  .display-2 {
    @include font-size($display2-size);

    /* rtl:remove */
    letter-spacing: $display2-spacing;
  }

  .display-3 {
    @include font-size($display3-size);

    /* rtl:remove */
    letter-spacing: $display3-spacing;
  }

  .display-4 {
    @include font-size($display4-size);
    line-height: $display-line-height;

    /* rtl:remove */
    letter-spacing: $display4-spacing;
  }

  h2 {
    @include font-size($h2-font-size);
    line-height: $h2-line-height;

    /* rtl:remove */
    letter-spacing: $h2-spacing;
  }

  h3 {
    @include font-size($h3-font-size);
    line-height: $h3-line-height;

    /* rtl:remove */
    letter-spacing: $h3-spacing;
  }

  h4 {
    @include font-size($h4-font-size);
    line-height: $h4-line-height;

    /* rtl:remove */
    letter-spacing: $h4-spacing;
  }

  h5,
  h6 {
    @include font-size($h5-font-size);
    line-height: $h5-line-height;

    /* rtl:remove */
    letter-spacing: $h5-spacing;
  }

  .lead {
    @include font-size($lead-font-size);
    line-height: $lead-line-height;

    /* rtl:remove */
    letter-spacing: $lead-letter-spacing;
  }
}
// End mod


//
// Emphasis
//
.small {
  @extend small;
}

.mark {
  @extend mark;
}

//
// Lists
//

.list-unstyled {
  @include list-unstyled();
}

// Inline turns list items into inline-block
.list-inline {
  @include list-unstyled();
}
.list-inline-item {
  display: inline-block;

  &:not(:last-child) {
    margin-right: $list-inline-padding;
  }
}

//
// Misc
//

// Builds on `abbr`
.initialism {
  @include font-size($initialism-font-size);
  text-transform: uppercase;
}

// Blockquotes
.blockquote {
  margin-bottom: $blockquote-margin-y;
  @include font-size($blockquote-font-size);
  line-height: $blockquote-line-height; // Boosted mod

  /* rtl:remove */
  letter-spacing: $blockquote-letter-spacing; // Boosted mod

  > :last-child {
    margin-bottom: 0;
  }
}

.blockquote-footer {
  margin-top: -$blockquote-margin-y;
  margin-bottom: $blockquote-margin-y;
  @include font-size($blockquote-footer-font-size);
  line-height: $line-height-sm; // Boosted mod
  color: $blockquote-footer-color;

  /* rtl:remove */
  letter-spacing: $letter-spacing-base; // Boosted mod

  &::before {
    content: "\2014\00A0"; // em dash, nbsp
  }
}
